@font-face {
    font-family: 'OpenDyslexic';
    src:         url('opendyslexic.woff') format('woff');
}
$font: 'OpenDyslexic', 'Helvetica', 'Verdana', sans-serif;

$green: #118f0e;
$middle: #dddddd;
$blue: #33bbee;
$text: #0069b3;
$white: #ffffff;
$header: #ff6600;
$header_2: #ff9900;