@import '../_variables.scss';

.toast {
    position: fixed;
    top: 0;
    right: 0;
    margin: 20px;
    padding: 0;
    border-radius: 5px;
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.1);
    color: #ffffff;
    animation: fade 0.5s ease 5s;
    animation-fill-mode: forwards;
    width: 560px;
    height: auto;
    z-index: 10000000;

    svg {
        padding: 20px;
        width: 60px;
        height: 60px;
        display: inline-block;
        box-sizing: border-box;
        border-right: 1px solid rgba(255, 255, 255, 0.2);

        path {
            fill: #ffffff;
        }
    }

    .message {
        padding: 20px;
        width: calc(100% - 80px);
        min-height: 60px;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
    }

    &.toast_error {
        background-color: #dc3545;
    }

    &.toast_warning {
        background-color: #ffc107;
    }

    &.toast_note {
        background-color: #17a2b8;
    }

    &.toast_success {
        background-color: #28a745;
    }
}

@keyframes fade {
    from {
        right: 0;
        opacity: 1;
    }
    to {
        right: 300px;
        opacity: 0;
        display: none;
    }
}