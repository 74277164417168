@import '_variables.scss';

html {
    background-color: $blue;
    font-family: $font;
    font-size: 14px;
    background-image: linear-gradient(180deg, $blue, $blue, $middle, $middle, $green);
    background-repeat: repeat-x;
    background-attachment: fixed;
    height: 100%;
}
body {
    margin: 0 auto;
    margin-top: 0;
    width: 1140px;
    min-height: 100%;
    background-color: rgba(255, 255, 255, 0.8);

    > div {
        padding-top: 20px;
        color: $header_2;
        font-size: 14px;
        line-height: 20px;
    }
}
header {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));

    > p {
        font-family: $font;
        margin: 0;
        margin-bottom: 100px;
        z-index: 1;
        left: 0;
        padding: 0;
        padding-left: 300px;
        display: block;
        width: 660px;
        height: 20px;
        color: $text;
        font-size: 16px;
        font-style: italic;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 40px;
    }
    > img {
        position: relative;
        top: 0;
        left: 0;
        z-index: 2;
        margin-bottom: -150px;
    }
}
h1 {
    top: 150px;
    z-index: 1;
    left: 0;
    padding-left: 300px;
    margin: 0;
    background-color: $header;
    display: block;
    width: 860px;
    height: 40px;
    color: $text;
    font-size: 2.2em;
    font-weight: bold;
    padding-top: 5px;
    letter-spacing: 4px;
    background-image: linear-gradient(270deg, $header_2, $header);
    box-shadow: 0 0 8px 6px rgba(63, 63, 63, 0.1);
    font-family: $font;;
    line-height: 38px;
}
h2,
h3 {
    margin-left: 20px;
    font-family: $font;
    letter-spacing: 2px
}
h2 {
    font-size: 2em;
    color: $text;
}
h3 {
    font-size: 1.6em;
    color: $blue;
}
a {
    color: $text;
    text-decoration: none;

    &:hover {
        border-bottom: 1px solid $header;
    }
}
p {
    padding: 20px;
    margin: 20px;
    letter-spacing: 2px;
    line-height: 25px;
}
li {
    letter-spacing: 2px;
    line-height: 25px;
    margin-bottom: 12px;
}
fieldset {
    border: 0;
    margin: 60px auto;
    width: 50vw;
    background-color: rgba( 51, 187, 238, 0.1);

    *:focus {
        background-color: $white;
        outline: 0;
    }
}
legend {
    color: $text;
}


menu {
    width: 1140px;
    display: block;
    margin: 0;
    padding: 0;

    &:after {
        clear: both;
    }

    li {
        list-style-type: none;
        color: $text;
        display: inline-block;
        margin: 0;
        padding: 0;
        text-align: center;
        background-color: $header_2;
        width: 280px;
    }
    a {
        font-family: $font;
        font-size: 18px;
        color: $text;
        text-decoration: none;
        display: block;
        width: 268px;
        margin: 2px;
        padding: 4px;
        letter-spacing: 1px;
        transition: all 2s ease-out;
    }
    a:hover,
    a:target,
    li.current a {
        background-color: $header;
        text-shadow: 1px 1px 4px $header;
        transition: all 2s ease-out;
    }
}
div {
    ul {
        margin: 20px 20px 20px 40px;
        padding: 20px;
    }
    p,
    li {
        color: $text;
    }
}

section {
    padding-top: 360px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        margin-bottom: -160px;
    }
}
p.footer {
    display: flex;
    justify-content: space-between;
}
@media screen and (min-width: 1366px) {
    header {
        position: fixed;
        top: 0;
        left: 50%;
        width: 1140px;
        margin-left: -570px;
    }
}
@media screen and (max-width: 1365px) {
    header {
        background: none !important;
    }
    menu {
        display: none;
    }
    fieldset {
        width: 100%;
        margin: 60px 0;
    }
}